// Polyfills for IE11. Next handles this automatically for app code, but anything in node_modules that isn't transpiled will need to be polyfilled. In this instance, it is Yup, our form validator.
import arrayFrom from 'core-js/library/fn/array/from'
import objectEntries from 'core-js/library/fn/object/entries'
import objectValues from 'core-js/library/fn/object/values'
import stringIncludes from 'core-js/library/fn/string/includes'

Array.from = arrayFrom
Object.entries = objectEntries
Object.values = objectValues
String.includes = stringIncludes
